var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"exam-group-adding"},[_c('v-container',[_c('ValidationObserver',{ref:"form_create_group",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"method":"post","id":"form_create_group"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitGroupQuestion)}}},[_c('v-row',[_c('v-col',[_c('p',[_vm._v("Chọn loại nhóm câu hỏi")])])],1),_c('v-select',{staticStyle:{"max-width":"80px"},attrs:{"items":_vm.listPart,"dense":"","solo":"","name":"name","outlined":""},model:{value:(_vm.group_question.part_id),callback:function ($$v) {_vm.$set(_vm.group_question, "part_id", $$v)},expression:"group_question.part_id"}}),_c('v-row'),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('h4',[_vm._v("Từ câu ")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('ValidationProvider',{attrs:{"rules":"required|number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.group_question.from),expression:"group_question.from"}],staticClass:"number-input",attrs:{"type":"text"},domProps:{"value":(_vm.group_question.from)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.group_question, "from", $event.target.value)}}}),_c('div',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('h4',[_vm._v("Đến câu")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('ValidationProvider',{attrs:{"rules":"required|number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.group_question.to),expression:"group_question.to"}],staticClass:"number-input",attrs:{"type":"text"},domProps:{"value":(_vm.group_question.to)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.group_question, "to", $event.target.value)}}}),_c('div',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1)],1),_c('v-col',{staticClass:"justify-end",attrs:{"cols":"6"}},[_c('v-row',{staticClass:"d-flex justify-end"},[_c('v-col',{attrs:{"cols":"4"}},[_c('h4',[_vm._v("Vị trí nhóm câu hỏi")])]),_c('v-col',{attrs:{"cols":"7"}},[_c('ValidationProvider',{attrs:{"rules":"required|number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.group_question.questionGroupNum),expression:"group_question.questionGroupNum"}],staticClass:"number-input",attrs:{"type":"text"},domProps:{"value":(_vm.group_question.questionGroupNum)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.group_question, "questionGroupNum", $event.target.value)}}}),_c('div',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('div',[_vm._v("Nhập tiêu đề nhóm câu hỏi thi")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('CkContent',{attrs:{"isValidate":_vm.isValidate,"content":_vm.group_question.title},on:{"getData":_vm.getData}}),_c('div',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(_vm.CkMessage)+" ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('div',[_vm._v("Nhập nội dung nhóm câu hỏi thi")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('CkContent',{attrs:{"content":_vm.group_question.content},on:{"getData":_vm.getData2}}),_c('div',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(_vm.CkMessage)+" ")])],1)],1),((_vm.exam_config_id == _vm.EXAM_CONFIG_ID.toeic_listening))?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"file"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{staticClass:"pl-1 upload-file-input",attrs:{"id":"fileUpload","prepend-icon":"mdi-soundcloud","label":"Upload File Audio"},on:{"change":_vm.onChangeFileUpload},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]),(_vm.audio_message)?_c('span',{staticClass:"validate-error text-danger text-danger",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(_vm.audio_message)+" ")]):_vm._e()]}}],null,true)}),(_vm.group_question.audio)?_c('audio',{ref:"audio",staticClass:"audio-block",attrs:{"controls":"controls"}},[_c('source',{attrs:{"src":_vm.group_question.audio && _vm.group_question.audio.src,"type":"audio/mpeg"}}),_vm._v(" Your browser does not support the audio element. ")]):_vm._e()],1)],1):_vm._e(),_c('v-row',{staticClass:"text-center"},[(_vm.checkMessage)?_c('v-col',[_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(_vm.checkMessage)+" ")])]):_vm._e(),(_vm.error_msg)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(_vm.error_msg)+" ")])]):_vm._e(),_c('v-col',[_c('v-btn',{attrs:{"type":"submit","form":"form_create_group","color":"primary"}},[_vm._v("Cập nhật")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }